/* About.css */
.about-container {
    padding: 20px;
}

.about-company {
    margin-bottom: 40px;
}

.about-company h2 {
    font-size: 28px;
    color: #A6700B;
    margin-bottom: 20px;
}

.about-company p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.executive-team {
    text-align: center;
}

.executive-team h2 {
    font-size: 28px;
    color: #A6700B;
    margin-bottom: 20px;
}

.team-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.team-member {
    width: 30%;
    text-align: center;
}

.team-member img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.team-member h3 {
    font-size: 22px;
    margin: 10px 0;
}

.team-member p {
    font-size: 16px;
    color: #555;
}

.team-connections {
    position: relative;
    margin-top: 20px;
}

.line {
    width: 2px;
    height: 150px;
    background-color: #1791c8;
    position: absolute;
}

#line1 {
    left: 20%;
}

#line2 {
    left: 50%;
    transform: translateX(-50%);
}

#line3 {
    right: 20%;
}



@media (min-width: 768px) {
    .x-navbar:not(.x-navbar-fixed-top) {
        background-color: transparent;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border: none;
    }
}

.container {
    transform-style: preserve-3d;
}

.container .box {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px;
    transform-style: preserve-3d;
    perspective: 1000px;
    cursor: pointer;
}

.container .box .body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease;
}



.container .box .body .imgContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.container .box .body .imgContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .box .body .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}

.container .box:hover .body {
    transform: rotateY(180deg);
}

.container .box .body .content div {
    transform-style: preserve-3d;
    padding: 20px;
    background: linear-gradient(45deg, #671857, #955164);
    transform: translateZ(100px);
}

.container .box .body .content div h3 {
    letter-spacing: 1px;
}


/* src/styles/About.css */
.recognition-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
}

.dpiit-recognition {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.dpiit-logo {
    width: 50px;

}



.download-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #1791c8;
    color: white;
    border-radius: 4px;
    text-decoration: none;
}

.download-link:hover {
    background-color: #0e6b91;
}