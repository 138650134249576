/* src/assets/styles/global.css */
* {
  box-sizing: border-box;
}


@import 'bootstrap/dist/css/bootstrap.min.css';
@import "bootstrap-icons/font/bootstrap-icons.css";


@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");



.banner {
  position: relative;
  overflow: hidden;
  padding: .5rem 1rem;
  height: 40vh;
  background-color: #dfdedf;
  /* background-image: -webkit-linear-gradient(30deg, #fff 45%, #bebbbe 45%); */
  background-image: linear-gradient(to right, #955164, #671857);
}

.background-animation {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
}

.background-animation .star-ani {
  position: absolute;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  top: 15%;
  left: 45%;
  z-index: -100;
  -webkit-animation: rotateAnimation linear 39s infinite;
  animation: rotateAnimation linear 39s infinite;
}

.background-animation .star-ani::after {
  position: absolute;
  content: "\f005";
  top: 10%;
  left: 10%;
  z-index: -1;
  border-radius: 50%;
  font-family: "Font Awesome 5 Free";
  color: #A6700B;
  font-size: 22px;
}

@keyframes rotateAnimation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.background-animation .box-ani {
  position: absolute;
  border-radius: 50%;
  -webkit-animation: rotateAnimation linear 37s infinite;
  animation: rotateAnimation linear 37s infinite;
  width: 15%;
  height: 18%;
  left: 7%;
  top: 70%;
  z-index: -5;
}

.welcome-area .welcome-content .animated {
  position: relative;
  z-index: 100;
  margin-top: 75px;
  color: white;
}

.welcome-content .animated {
  color: white;
}

.background-animation .box-ani::after {
  width: 26px;
  height: 26px;
  position: absolute;
  content: "";
  z-index: -5;
  background-color: transparent;
  border-radius: 4px;
  border: 3px solid #A6700B;
}

.background-animation .cloud-ani {
  position: absolute;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  top: 50%;
  left: 60%;
  z-index: -1;
  -webkit-animation: rotateAnimation linear 46s infinite;
  animation: rotateAnimation linear 46s infinite;
}

.background-animation .cloud-ani::after {
  position: absolute;
  content: "\f005";
  top: 10%;
  left: 10%;
  z-index: -1;
  border-radius: 50%;
  font-family: "Font Awesome 5 Free";
  color: #A6700B;
  font-size: 2.5rem;
}

.background-animation .triangle-ani {
  position: absolute;
  border-radius: 50%;
  -webkit-animation: rotateAnimation linear 40s infinite;
  animation: rotateAnimation linear 40s infinite;
  width: 20%;
  height: 20%;
  left: 30%;
  top: 50%;
  z-index: -1;
}

.background-animation .triangle-ani::after {
  width: 0;
  height: 0;
  position: absolute;
  content: "";
  border-top: 14px solid #A6700B;
  border-bottom: 14px solid transparent;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  z-index: -1;
}



.background-animation .circle-ani {
  position: absolute;
  border-radius: 50%;
  -webkit-animation: rotateAnimation linear 34s infinite;
  animation: rotateAnimation linear 34s infinite;
  width: 180px;
  height: 180px;
  left: 10%;
  top: 10%;
  z-index: -1;
}

.background-animation .circle-ani::after {
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
  top: 10%;
  left: 10%;
  border: 3px solid #A6700B;
  z-index: -1;
  border-radius: 50%;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.animatedFadeInUp {
  opacity: 0;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.first {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.second {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.third {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

.fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.fifth {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}



nav.bg-light {
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  animation: animatedBackground 40s linear infinite;
  transition: .6s ease all;
}

.toptobutton {

  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
  cursor: pointer;
}

/*   
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    display: inline;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  } */

main {
  padding: 20px;
}

nav {

  padding: 0 20px 0 20px;
}

.company-name {
  padding: 1rem;
  font-size: 1.5rem;
  margin: 0;
  color: #333;
  /* Default color */
}


.company-name .maru {
  color: #5D0A58;
  /* Black color for MARU */
}

.company-name .mitra {
  color: #d48e0d;
  /* Blue color for MiTRA */
}


.hero {
  /* display: flex; */
  align-items: center;
}

.header-margin {
  margin-top: 4rem;
}

.hero-image {
  /* Fixed width of 20% */
  /* Fixed height of 25% */
  overflow: hidden;
  /* Add space between the image and content */
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.contact-section {
  align-content: center;
  background-color: #ffff;
  box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);

  /* Adjust margin as needed */
}

.avatar {
  max-width: 100%;
  height: auto;
  /* Example styles for the avatar image */
}

.avatar img {
  width: 8em;
  max-height: 5em;
  border-radius: 0 0 50px 0;
  background-color: #a7a5a5;
}

.right-section-info {
  text-align: center;
  height: 100%;
}


.contact-button-home {
  display: inline-block;
  text-decoration: none;
  border: 2px solid #671857;
  border-radius: 100px;
  padding: .9em 1.3em;
  margin: 5px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, #671857 40%);
  transition: background-position .3s cubic-bezier(.19, 1, .22, 1) .1s, color .5s ease 0s, background-color .5s ease;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 0 #671857;
  cursor: pointer;
  animation: pulse 1.25s infinite cubic-bezier(.66, 0, 0, 1);
  color: #671857;
}

.accordion-header {
  background: #955164;
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 20px rgba(103, 24, 87, 0);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.instagram {
  color: #E1306C;
}


ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background-image: linear-gradient(to right, #955164, #671857);
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #A6700B;
}

.single-cta i {
  color: #A6700B;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #d2d2d2;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #d2d2d2;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #A6700B;
}

.footer-widget ul li {
  display: inline-block;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #A6700B;
  text-decoration: underline;
}

.footer-widget ul li a {
  color: #d2d2d2;
  text-transform: capitalize;
  text-decoration: none;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #d20c0c;
  padding: 13px 20px;
  border: 1px solid #650303;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background-image: linear-gradient(to right, #671857, #955164);
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #ffff;
}

.copyright-text p a {
  color: #A6700B;
  text-decoration: none;
}

.copyright-text p a:hover {
  text-decoration: underline;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #A6700B;
  text-decoration: underline;
}

.footer-menu li a {
  font-size: 14px;
  color: #d2d2d2;
  text-decoration: none;
}

@media (max-width: 991px) {
  .tab-content>.tab-pane {
    display: block;
    opacity: 1;
  }
}

.navbar-nav .nav-link.active {
  color: #d48e0d;
}

.navbar-toggler {
  columns: #A6700B;
  border: var(--bs-border-width) solid #A6700B;
}

.navbar-toggler-icon {
  columns: #A6700B;
  background-image: #A6700B
}

.bg-company {
  /* background-color: #671857; */
  background-image: linear-gradient(to right, #955164, #671857);
  padding-top: 1%;
  padding-bottom: 1%;
}

.text-company {
  color: #e7c88f;
}

/* .about-company h2 {
  color: #A6700B;
} */

.company-name .logoimg {
  width: 50px;
  height: 50px;
}

/* src/styles/AnnouncementBanner.css */
.announcement-banner {
  background-image: linear-gradient(to right, #955164, #671857);
  color: white;
  text-align: center;
  padding: 10px;
}