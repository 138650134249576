@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.banner {
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding: .5rem 1rem;
    height: 40vh;
    background-color: #b3c2cc;
    background-image: -webkit-linear-gradient(30deg, #cfdce2 45%, #aab9c2 45%);
}

.background-animation {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
}

.background-animation .star-ani {
    position: absolute;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    top: 15%;
    left: 45%;
    z-index: -1;
    -webkit-animation: rotateAnimation linear 39s infinite;
    animation: rotateAnimation linear 39s infinite;
}

.background-animation .star-ani::after {
    position: absolute;
    content: "\f005";
    top: 10%;
    left: 10%;
    z-index: -1;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    color: rgba(0, 0, 0, 0.26);
    font-size: 22px;
}

@keyframes rotateAnimation {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.background-animation .box-ani {
    position: absolute;
    border-radius: 50%;
    -webkit-animation: rotateAnimation linear 37s infinite;
    animation: rotateAnimation linear 37s infinite;
    width: 15%;
    height: 18%;
    left: 7%;
    top: 70%;
    z-index: -5;
}

.welcome-area .welcome-content {
    position: relative;
    z-index: 100;
    margin-top: 75px;
}

.background-animation .box-ani::after {
    width: 26px;
    height: 26px;
    position: absolute;
    content: "";
    z-index: -5;
    background-color: transparent;
    border-radius: 4px;
    border: 3px solid rgba(0, 0, 0, .2);
}

.background-animation .cloud-ani {
    position: absolute;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    top: 50%;
    left: 60%;
    z-index: -1;
    -webkit-animation: rotateAnimation linear 46s infinite;
    animation: rotateAnimation linear 46s infinite;
}

.background-animation .cloud-ani::after {
    position: absolute;
    content: "\f005";
    top: 10%;
    left: 10%;
    z-index: -1;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    color: rgba(0, 0, 0, .26);
    font-size: 2.5rem;
}

.background-animation .triangle-ani {
    position: absolute;
    border-radius: 50%;
    -webkit-animation: rotateAnimation linear 40s infinite;
    animation: rotateAnimation linear 40s infinite;
    width: 20%;
    height: 20%;
    left: 30%;
    top: 50%;
    z-index: -1;
}

.background-animation .triangle-ani::after {
    width: 0;
    height: 0;
    position: absolute;
    content: "";
    border-top: 14px solid rgba(0, 0, 0, .16);
    border-bottom: 14px solid transparent;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    z-index: -1;
}

.background-animation .circle-ani {
    position: absolute;
    border-radius: 50%;
    -webkit-animation: rotateAnimation linear 34s infinite;
    animation: rotateAnimation linear 34s infinite;
    width: 180px;
    height: 180px;
    left: 10%;
    top: 10%;
    z-index: -1;
}

.background-animation .circle-ani::after {
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    top: 10%;
    left: 10%;
    border: 3px solid rgba(0, 0, 0, .2);
    z-index: -1;
    border-radius: 50%;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.animatedFadeInUp {
    opacity: 0;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.first {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

.second {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.third {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}

.fourth {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.fifth {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

@keyframes fadeInUp {
    0% {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}